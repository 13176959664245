import { onDomReady } from 'utils/onDomReady'
import debounce from 'utils/debounce'
import { on } from 'delegated-events'
import { trackEvent } from 'api/index'

import { updateSelectedItemsOnPageLoad } from './multiselect'

const DASHBOARD_SEARCH_SELECTOR = '.dashboards-search__input'
const ITEM_HIDDEN_CLASSNAME = 'dashboard--hidden'
const DASHBOARD_MULTISELECT_CLEAR_SELECTOR =
  '.dashboards__filter .multiselect__clear'
const DASHBOARD_LOAD_MORE_SELECTOR = '.load-more-dashboards'
const DASHBOARD_ACTION_ANCHOR = '.dashboard-action-anchor'

const itemsPerPage = () => {
  if (window.matchMedia('(max-width: 650px)').matches) {
    return 10
  }
  if (window.matchMedia('(max-width: 1022px)').matches) {
    return 10
  }
  return 9
}

let visibleItemsCount = itemsPerPage()
let categoryValue = []
let sourceValue = []
let destinationValue = []
let searchValue = ''
let fullItemList = []
let initialOrder = []

function updateURL() {
  const urlParams = new URLSearchParams()

  if (categoryValue.length > 0) {
    categoryValue.forEach(category => {
      urlParams.append('category', category)
    })
  }

  if (sourceValue.length > 0) {
    sourceValue.forEach(source => {
      urlParams.append('source', source)
    })
  }

  if (destinationValue.length > 0) {
    destinationValue.forEach(destination => {
      urlParams.append('destination', destination)
    })
  }

  window.history.replaceState(null, '', `?${urlParams.toString()}`)
}

const scrollToStart = function () {
  const start = document.querySelector(
    '.data-filter-section--dashboards .anchor'
  )
  start.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

const handleCheckboxChange = function () {
  const value = this.value
  let array, eventName

  switch (this.name) {
    case 'categories-checkboxes':
      array = categoryValue
      eventName = 'Marketing:Templates:Category selected'
      break
    case 'sources-checkboxes':
      array = sourceValue
      eventName = 'Marketing:Templates:Source selected'
      break
    case 'destinations-checkboxes':
      array = destinationValue
      eventName = 'Marketing:Templates:Destination selected'
      break
    default:
      return
  }

  if (this.checked) array.push(value)
  else array.splice(array.indexOf(value), 1)

  updateURL()
  filterList()

  trackEvent(eventName, { name: array.join(', ') })
  scrollToStart()
}

const filterList = () => {
  const list = document.querySelector('.data-filter-section .dashboards__list')
  if (!list) return

  const items = fullItemList

  const isItemMatched = item => {
    const {
      title,
      categories: itemCategories,
      sources: itemSources,
      destinations: itemDestinations
    } = item.parsedData

    const isSearchMatch = searchValue.length
      ? [title, ...itemSources, ...itemDestinations].some(it =>
          it.toLowerCase().trim().includes(searchValue)
        )
      : true

    const isCategoriesMatch = categoryValue.length
      ? categoryValue.some(item => itemCategories.includes(item))
      : true

    const isSourcesMatch = sourceValue.length
      ? sourceValue.some(item => itemSources.includes(item))
      : true

    const isDestinationsMatch = destinationValue.length
      ? destinationValue.some(item => itemDestinations.includes(item))
      : true

    return (
      isSearchMatch &&
      isCategoriesMatch &&
      isSourcesMatch &&
      isDestinationsMatch
    )
  }

  const getSourceCount = item => {
    return item.parsedData.sources.length
  }

  if (sourceValue.length > 0) {
    items.sort((a, b) => {
      const sourceCountA = getSourceCount(a)
      const sourceCountB = getSourceCount(b)

      if (sourceCountA === 1 && sourceCountB !== 1) {
        return -1
      } else if (sourceCountA !== 1 && sourceCountB === 1) {
        return 1
      } else {
        return 0
      }
    })
  } else {
    items.sort((a, b) => initialOrder.indexOf(a) - initialOrder.indexOf(b))
  }

  list.innerHTML = ''

  let showedMatchedItemsCount = 0
  let matchedItemsCount = 0

  items.forEach(item => {
    if (isItemMatched(item)) {
      if (showedMatchedItemsCount < visibleItemsCount) {
        item.classList.remove(ITEM_HIDDEN_CLASSNAME)
        showedMatchedItemsCount++
      } else {
        item.classList.add(ITEM_HIDDEN_CLASSNAME)
      }
      list.appendChild(item)
      matchedItemsCount++
    } else {
      item.classList.add(ITEM_HIDDEN_CLASSNAME)
    }
  })

  const isEmpty = items.every(item =>
    item.classList.contains(ITEM_HIDDEN_CLASSNAME)
  )

  list.classList.toggle('is-empty', isEmpty)

  const remainingItems = matchedItemsCount - visibleItemsCount

  document.querySelector(DASHBOARD_LOAD_MORE_SELECTOR).style.display =
    remainingItems > 0 ? 'inline-flex' : 'none'
}

on('submit', '.dashboards__filter', e => {
  e.preventDefault()
})

on(
  'input',
  DASHBOARD_SEARCH_SELECTOR,
  debounce(e => {
    searchValue = e.target.value?.toLowerCase()?.trim() ?? ''
    filterList()
    trackEvent('Marketing:Templates:Search', { name: searchValue })
  })
)

on(
  'change',
  `[name="sources-checkboxes"], [name="categories-checkboxes"], [name="destinations-checkboxes"]`,
  handleCheckboxChange
)

const syncFiltersFromURL = function () {
  const urlParams = new URLSearchParams(window.location.search)

  categoryValue = urlParams.getAll('category')
  sourceValue = urlParams.getAll('source')
  destinationValue = urlParams.getAll('destination')
  searchValue = ''

  const categoryCheckboxes = document.querySelectorAll(
    `[name="categories-checkboxes"]`
  )

  const sourceCheckboxes = document.querySelectorAll(
    `[name="sources-checkboxes"]`
  )

  const destinationCheckboxes = document.querySelectorAll(
    `[name="destinations-checkboxes"]`
  )

  categoryCheckboxes.forEach(checkbox => {
    const checkboxValue = checkbox.value
    checkbox.checked = categoryValue.includes(checkboxValue)
  })

  sourceCheckboxes.forEach(checkbox => {
    const checkboxValue = checkbox.value
    checkbox.checked = sourceValue.includes(checkboxValue)
  })

  destinationCheckboxes.forEach(checkbox => {
    const checkboxValue = checkbox.value
    checkbox.checked = destinationValue.includes(checkboxValue)
  })

  updateSelectedItemsOnPageLoad()
}

onDomReady(() => {
  const isMarketingDashboardsPage =
    window.location.pathname === '/marketing-dashboards'

  syncFiltersFromURL()

  if (isMarketingDashboardsPage && categoryValue.length === 0) {
    categoryValue = ['Marketing']
    updateURL()
  }

  fullItemList = Array.from(
    document.querySelectorAll('.data-filter-section .dashboard')
  ).map(item => {
    item.parsedData = {
      title: item.dataset.title,
      text: item.dataset.text,
      categories: JSON.parse(item.dataset.categories),
      sources: JSON.parse(item.dataset.sources),
      destinations: JSON.parse(item.dataset.destinations)
    }
    return item
  })

  initialOrder = fullItemList.slice()

  filterList()
})

on('click', DASHBOARD_LOAD_MORE_SELECTOR, () => {
  visibleItemsCount += itemsPerPage()
  filterList()
})

on('click', DASHBOARD_MULTISELECT_CLEAR_SELECTOR, event => {
  const { collection } = event.target.dataset
  switch (collection) {
    case 'categories': {
      categoryValue = []
      break
    }
    case 'sources': {
      sourceValue = []
      break
    }
    case 'destinations': {
      destinationValue = []
      break
    }
  }
  updateURL()
  filterList()
})

on('click', DASHBOARD_ACTION_ANCHOR, e => {
  e.preventDefault()

  const href = e.target.closest(DASHBOARD_ACTION_ANCHOR).getAttribute('href')

  const [baseUrl, anchor] = href.split('#')

  if (baseUrl) {
    const newParams = new URLSearchParams(baseUrl.split('?')[1] || '')

    const newUrl = `${window.location.pathname}?${newParams.toString()}${
      anchor ? `#${anchor}` : ''
    }`

    window.history.pushState(null, '', newUrl)

    syncFiltersFromURL()
    filterList()
  }

  if (anchor) {
    const targetElement = document.querySelector(`#${anchor}`)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
})
