import { on } from 'delegated-events'
import { onDomReady } from 'utils/onDomReady'

const MULTISELECT_SELECTOR = '.multiselect'
const MULTISELECT_CLEAR_SELECTOR = '.multiselect__clear'
const MULTISELECT_TOGGLE_SELECTOR = '.multiselect__toggle'
const MULTISELECT_CHECKBOX_SELECTOR = '.multiselect__checkbox'

const MULTISELECT_ACTIVE_CLASSNAME = 'multiselect--active'

function toggleDropdown(event) {
  const dropdownToggle = event.target
  const dropdownMenu = dropdownToggle.closest(MULTISELECT_SELECTOR)
  dropdownMenu.classList.toggle(MULTISELECT_ACTIVE_CLASSNAME)
}

function outsideDropdownClick(event) {
  const target = event.target
  const dropdowns = document.querySelectorAll(MULTISELECT_SELECTOR)

  dropdowns.forEach(dropdown => {
    if (!dropdown.contains(target)) {
      dropdown.classList.remove(MULTISELECT_ACTIVE_CLASSNAME)
    }
  })
}

function updateSelectedItems(multiselect, clearButton) {
  const selectedItemsContainer = multiselect.querySelector(
    MULTISELECT_TOGGLE_SELECTOR
  )
  const originalText = selectedItemsContainer.getAttribute('data-text')
  const checkboxes = multiselect.querySelectorAll(
    MULTISELECT_CHECKBOX_SELECTOR + ':checked'
  )

  if (checkboxes.length === 0) {
    selectedItemsContainer.textContent = `All ${originalText}`
  } else if (checkboxes.length === 1) {
    selectedItemsContainer.textContent = checkboxes[0].value
  } else {
    selectedItemsContainer.textContent = `${checkboxes.length} ${originalText} selected`
  }

  clearButton.textContent = `Clear All ${
    checkboxes.length ? '(' + checkboxes.length + ')' : ''
  }`
}

export function updateSelectedItemsOnPageLoad() {
  const multiselects = document.querySelectorAll(MULTISELECT_SELECTOR)
  multiselects.forEach(multiselect => {
    const clearButton = multiselect.querySelector(MULTISELECT_CLEAR_SELECTOR)
    updateSelectedItems(multiselect, clearButton)
  })
}

function clearAllItems(event) {
  event.preventDefault()
  const multiselect = this.closest(MULTISELECT_SELECTOR)
  const checkboxes = multiselect.querySelectorAll(
    MULTISELECT_CHECKBOX_SELECTOR + ':checked'
  )
  const clearButton = multiselect.querySelector(MULTISELECT_CLEAR_SELECTOR)

  checkboxes.forEach(checkbox => {
    checkbox.checked = false
  })

  updateSelectedItems(multiselect, clearButton)
}

on('click', MULTISELECT_TOGGLE_SELECTOR, toggleDropdown)
document.addEventListener('click', outsideDropdownClick)

on('change', MULTISELECT_CHECKBOX_SELECTOR, event => {
  event.stopPropagation()
  const multiselectMenu = event.target.closest(MULTISELECT_SELECTOR)
  const clearButton = multiselectMenu.querySelector(MULTISELECT_CLEAR_SELECTOR)
  updateSelectedItems(multiselectMenu, clearButton)
})

on('click', MULTISELECT_CLEAR_SELECTOR, clearAllItems)

onDomReady(() => {
  updateSelectedItemsOnPageLoad()
})
